export const BRAND_NAME: string =
  "PottersTouch LLC - Child-Centric Healthcare & Transportation Services";

export const BRAND_TAGLINE: string =
  "Prioritizing the Health and Safety of Our Young Clients";

export const BRAND_DESCRIPTION: string =
  "PottersTouch LLC excels in delivering top-tier, child-focused post-acute, long-term care, and transportation services. Our dedicated team of empathetic professionals provides exceptional clinical and transport solutions. While we specialize in children's healthcare, we extend our services to all age groups, ensuring an environment of safety, healing, and overall wellness.";

export const BRAND_URL: string = "https://www.potterstouch.org";

export const BRAND_EMAIL: string = "potterstouchagency@gmail.com";
export const BRAND_IMAGE: string =
  "https://res.cloudinary.com/deyudesls/image/upload/v1667430480/rounded%20logo.webp";

export const BRAND_FAVICON: string = "/android-chrome-192x192.png";

export const classNames = (...classes: any) => {
  return classes.filter(Boolean).join(" ");
};
