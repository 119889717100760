import React from "react";
import Image from "next/image";
import Link from "next/link";
import { Popover } from "@headlessui/react";
import { BRAND_IMAGE } from "../lib";

const Header = () => {
  return (
    <header>
      <Popover className="relative bg-white">
        <div className="mx-auto flex max-w-7xl items-center justify-between px-4 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-8">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <Link href="/">
              <span className="cursor-pointer">
                <span className="sr-only">Your Company</span>
                <Image
                  src={BRAND_IMAGE}
                  alt="logo"
                  width={100}
                  height={100}
                  blurDataURL={BRAND_IMAGE}
                  placeholder="blur"
                />
              </span>
            </Link>
          </div>

          <div className="items-center justify-end md:flex md:flex-1 lg:w-0">
            {/* <a
            href="#"
            className="whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900"
          >
            Sign in
          </a> */}
            <Link href="https://api.whatsapp.com/send?phone=13476540706">
              <a className="ml-8 inline-flex items-center justify-center whitespace-nowrap rounded-md border border-transparent bg-gradient-to-r from-cyan-600 to-cyan-600 bg-origin-border px-4 py-2 text-base font-medium text-white shadow-sm hover:from-cyan-700 hover:to-cyan-700">
                WhatsApp Us
              </a>
            </Link>
          </div>
        </div>
      </Popover>
    </header>
  );
};

export default Header;
