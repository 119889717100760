export default function Map() {
  return (
    <div className="mx-auto max-w-lg flex-1">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3052.0177598169853!2d-82.94984272374967!3d40.097316971492816!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8838f52bac59b121%3A0x26c9c83d45857a52!2s2600%20Tiller%20Ln%2C%20Columbus%2C%20OH%2043231%2C%20USA!5e0!3m2!1sen!2sgh!4v1692322755851!5m2!1sen!2sgh"
        className="w-full h-56 border-0"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
}
