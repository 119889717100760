import { Popover } from "@headlessui/react";
import { BRAND_EMAIL, BRAND_NAME, BRAND_TAGLINE } from "../lib";
import {
  BellAlertIcon,
  BuildingOffice2Icon,
  CheckCircleIcon,
  HomeIcon,
  InformationCircleIcon,
  TruckIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { ShieldCheckIcon } from "@heroicons/react/20/solid";
import Image from "next/image";
import Link from "next/link";
import MetaComponent from "../components/Meta";
import Testimonial from "../components/Testimonials";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Map from "../components/Map";

const MAIN_IMAGE: string =
  "https://res.cloudinary.com/deyudesls/image/upload/v1692324885/photo-1470116945706-e6bf5d5a53ca_r14ems.webp";
const MID_IMAGE: string =
  "https://res.cloudinary.com/deyudesls/image/upload/v1692324930/photo-1565615833231-e8c91a38a012_mrxfuq.webp";
const LAST_IMAGE: string =
  "https://res.cloudinary.com/deyudesls/image/upload/v1689647480/photo-1452715923772-3af19799aef8_xqsjic.webp";

const features = [
  {
    name: "In-home Services",
    description:
      "We offer hands-on assistance with activities such as grooming, ensuring safety, and aiding with independent ambulation, with special attention to the needs of children and elderly.",
    icon: HomeIcon,
  },
  {
    name: "Medical Services",
    description: `Our skilled therapies include physical therapy, occupational therapy, and speech-language
      pathology, which are particularly beneficial for children's development. Our healthcare professionals, including registered nurses and advanced nurse practitioners, provide dedicated care for each patient, regardless of age.`,
    icon: BuildingOffice2Icon,
  },
  {
    name: "Pediatric and Adult Nursing",
    description: `Our specialized pediatric nurses make regular home visits to ensure the overall well-being of all children under our care. At the same time, our adult nursing staff conducts thorough assessments for adult clients, serving as patient advocates.`,
    icon: UsersIcon,
  },
  {
    name: "Home Health Aides",
    description: `Our home health aides assist patients of all ages with medication administration, meal preparation, feeding, and light housekeeping. They engage children in fun daily activities, provide companionship, and support adults with everyday tasks.`,
    icon: ShieldCheckIcon,
  },
  {
    name: "Transportation",
    description: `We provide safe and comfortable rides for school drop-offs, doctors’ appointments, medication pickups, and grocery shopping, with special assistance for wheelchair accessibility.`,
    icon: TruckIcon,
  },
  {
    name: "Comprehensive Care Coordinator",
    description: `Our care coordinators manage all aspects of patient care, from scheduling children's activities to coordinating adult healthcare appointments. They ensure seamless, coordinated care for all ages, alleviate the stress of managing multiple healthcare needs, and keep family members updated on their loved ones' progress.`,
    icon: BellAlertIcon,
  },
];

const metrics = [
  {
    id: 1,
    stat: "Companionship",
    description:
      "Tailored to children's needs while being suitable for all age groups.",
  },
  {
    id: 2,
    stat: "Cognitive Social Interaction",
    description:
      "Promoting cognitive development in children and maintaining cognitive health in adults.",
  },
  {
    id: 3,
    stat: "Errands",
    description:
      "Assistance with tasks, with an emphasis on those suitable for children, such as educational errands.",
  },
  {
    id: 4,
    stat: "Transportation",
    description:
      "Safe and reliable transportation services, from school runs to medical appointments.",
  },
  {
    id: 5,
    stat: "Medication Pickup",
    description:
      "Coordinated and secure medication pick-up service, ensuring the health needs of all clients are met.",
  },
  {
    id: 6,
    stat: "Meal Prep",
    description:
      "Preparation of healthy and child-friendly meals, catering to the nutritional needs of all clients.",
  },
  {
    id: 7,
    stat: "Light Housekeeping",
    description:
      "Maintaining a clean and organized environment that's safe for children and comfortable for all clients.",
  },
];

const footerNavigation = {
  company: [{ name: "About", href: "/about" }],
  legal: [
    { name: "Privacy", href: "/privacy" },
    { name: "Terms", href: "terms" },
  ],
};

export default function Home() {
  return (
    <>
      <MetaComponent title={`${BRAND_NAME} | ${BRAND_TAGLINE}`} />

      <div className="bg-white">
        <Header />
        <main>
          {/* Hero section */}
          <div className="relative isolate pt-14">
            <div className="absolute inset-0 -z-10 h-full w-full object-cover">
              <Image
                src={MAIN_IMAGE}
                alt="People working on laptops"
                layout="fill"
                sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
                placeholder="blur"
                className="absolute inset-0 -z-10 h-full w-full object-cover"
                blurDataURL={MAIN_IMAGE}
              />
            </div>

            <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
              <div className="text-center m-4">
                <h1 className="text-4xl font-bold tracking-tight text-white sm:text-6xl">
                  Take control of your health and wellness
                </h1>
                <p className="mt-6 text-lg leading-8 font-bold text-white">
                  {BRAND_TAGLINE}
                </p>
                <div className="mt-10 flex items-center justify-center gap-x-6">
                  <Link href="tel:13476540706">
                    <a className="flex items-center cursor justify-center rounded-md border border-transparent bg-white px-4 py-3 text-base font-medium text-cyan-700 shadow-sm hover:bg-cyan-50 sm:px-8">
                      Call Us
                    </a>
                  </Link>
                  <Link href="https://api.whatsapp.com/send?phone=13476540706">
                    <a className="flex items-center justify-center rounded-md border border-transparent bg-cyan-500 bg-opacity-60 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-opacity-70 sm:px-8">
                      Text on WhatsApp
                    </a>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
              aria-hidden="true"
            >
              <div
                className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-20 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                style={{
                  clipPath:
                    "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                }}
              />
            </div>
          </div>

          <div className="bg-gray-100">
            <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8 flex flex-col items-center justify-center space-y-4">
              <h2 className="text-center text-3xl font-bold text-gray-900">
                <span className="flex items-center justify-center h-12 w-12 rounded-md bg-gradient-to-r from-cyan-600 to-cyan-600">
                  <CheckCircleIcon
                    className="h-6 w-6 text-white"
                    aria-hidden="true"
                  />
                </span>
              </h2>
              <p className="text-center text-base font-semibold text-gray-500 uppercase">
                Trusted by all who use our services
              </p>
              <p className="text-center text-lg text-gray-500">
                PottersTouch is committed to providing the best possible care
                for your loved ones. We specialize in offering top-tier,
                child-focused care and transportation services, and we&rsquo;re
                trusted by families across Ohio. With a gentle and compassionate
                approach, we&rsquo;re here to ensure the safety and wellness of
                those under our care.
              </p>
            </div>
          </div>

          {/* Alternating Feature Sections */}
          <div className="relative overflow-hidden pt-16 pb-32">
            <div
              aria-hidden="true"
              className="absolute inset-x-0 top-0 h-48 bg-gradient-to-b from-gray-100"
            />
            <div className="relative">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 grid grid-cols-1 lg:grid-flow-col lg:grid-cols-2 lg:gap-24">
                <div className="py-16 lg:mx-0">
                  <div>
                    <div>
                      <span className="flex h-12 w-12 items-center justify-center rounded-md bg-gradient-to-r from-cyan-600 to-cyan-600">
                        <InformationCircleIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div className="mt-6">
                      <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                        What we do
                      </h2>
                      <p className="mt-4 text-lg text-gray-500">
                        At PottersTouch LLC, we are passionate about providing
                        top-tier, child-focused post-acute, long-term care, and
                        transportation services. Our core mission is to deliver
                        excellent clinical and transport solutions with a
                        gentle, compassionate approach. While our primary focus
                        is on children&apos;s healthcare, our services cater to
                        all age groups. We strive to foster an environment of
                        safety, healing, and comprehensive wellness for every
                        client.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-12 sm:mt-16 lg:mt-0 relative h-64 lg:h-auto">
                  <div className="h-full pl-4 sm:pl-6 lg:px-0">
                    <Image
                      className="w-full h-full rounded-xl shadow-xl ring-1 ring-black ring-opacity-5 object-cover"
                      src={MID_IMAGE}
                      alt="Inbox user interface"
                      loading="lazy"
                      blurDataURL={MID_IMAGE}
                      placeholder="blur"
                      layout="fill"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Gradient Feature Section */}
          <div className="bg-gradient-to-r from-cyan-800 to-cyan-700">
            <div className="mx-auto max-w-4xl px-4 py-16 sm:px-6 sm:pt-20 sm:pb-24 lg:max-w-7xl lg:px-8 lg:pt-24">
              <h2 className="text-3xl font-bold tracking-tight text-white">
                What we offer
              </h2>
              <p className="mt-4 max-w-3xl text-lg text-cyan-200">
                We offer a wide range of services to meet the needs of our
                clients.
              </p>
              <div className="mt-12 grid grid-cols-1 gap-x-6 gap-y-12 sm:grid-cols-2 lg:mt-16 lg:grid-cols-3 lg:gap-x-8 lg:gap-y-16">
                {features.map((feature) => (
                  <div key={feature.name}>
                    <div>
                      <span className="flex h-12 w-12 items-center justify-center rounded-md bg-white bg-opacity-10">
                        <feature.icon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div className="mt-6">
                      <h3 className="text-lg font-medium text-white">
                        {feature.name}
                      </h3>
                      <p className="mt-2 text-base text-white">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {/* Stats section */}
          <div className="relative bg-gray-900">
            <div className="absolute inset-x-0 bottom-0 h-80 xl:top-0 xl:h-full">
              <div className="h-full w-full xl:grid xl:grid-cols-2">
                <div className="h-full xl:relative xl:col-start-2">
                  <Image
                    className="h-full w-full object-cover opacity-25 xl:absolute xl:inset-0"
                    src={LAST_IMAGE}
                    alt="People working on laptops"
                    loading="lazy"
                    blurDataURL={LAST_IMAGE}
                    placeholder="blur"
                    layout="fill"
                  />
                  <div
                    aria-hidden="true"
                    className="absolute inset-x-0 top-0 h-32 bg-gradient-to-b from-gray-900 xl:inset-y-0 xl:left-0 xl:h-full xl:w-32 xl:bg-gradient-to-r"
                  />
                </div>
              </div>
            </div>
            <div className="mx-auto max-w-4xl px-4 sm:px-6 lg:max-w-7xl lg:px-8 xl:grid xl:grid-cols-12 xl:gap-x-8">
              <div className="relative pt-12 pb-64 sm:pt-24 sm:pb-64 xl:col-span-8 xl:pb-24">
                <h2 className="text-base font-semibold">
                  <span className="bg-gradient-to-r from-cyan-300 to-cyan-300 bg-clip-text text-transparent">
                    Daily Activities
                  </span>
                </h2>
                <p className="mt-3 text-3xl font-bold tracking-tight text-white">
                  We specialize in organizing a wide range of daily activities,
                  tailored for the enrichment of our young clients. While our
                  focus is children, we cater to all age groups. Our activities
                  include:
                </p>

                <div className="mt-12 grid grid-cols-1 gap-y-12 gap-x-6 sm:grid-cols-2">
                  {metrics.map((item) => (
                    <div key={item.id}>
                      <span className="block text-2xl font-bold text-white">
                        {item.stat}
                      </span>
                      <p className="text-white">{item.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* CTA Section */}
          <div className="bg-white">
            <div className="mx-auto max-w-4xl py-16 px-4 sm:px-6 sm:py-24 lg:flex lg:max-w-7xl lg:items-center lg:justify-between lg:px-8">
              <h2 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                <span className="block">Round-the-clock support?</span>
                <span className="-mb-1 block bg-gradient-to-r from-cyan-600 to-cyan-600 bg-clip-text pb-1 text-transparent">
                  We provide 24/7 support all year round.
                </span>
              </h2>
            </div>
          </div>

          <Testimonial />

          <div className="bg-white">
            <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8 flex-1">
              <div className="mx-auto max-w-lg">
                <div>
                  <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight">
                    Visit Us
                  </h2>
                  <div className="mt-3">
                    <p className="text-lg text-gray-500">
                      2600 Tiller lane suite c5 Columbus ohio 43231
                    </p>
                  </div>
                  <div className="mt-9">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <PhoneIcon
                          className="h-6 w-6 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 text-base text-gray-500">
                        <p>
                          <Link href={"tel:16145566978"}>+1 740-819-1553</Link>
                        </p>

                        <p>
                          <Link href={"tel:13476540706"}>+1 347-654-0706</Link>
                        </p>
                        <p className="mt-1">Mon-Fri 8am to 6pm EST</p>
                      </div>
                    </div>

                    <div className="mt-6 flex">
                      <div className="flex-shrink-0">
                        <EnvelopeIcon
                          className="h-6 w-6 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3 text-base text-gray-500">
                        <p>
                          <Link
                            href={`mailto:${BRAND_EMAIL}?subject=Hello%20PottersTouch`}
                          >
                            {BRAND_EMAIL}
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex-1">
              <Map />
            </div>
          </div>
        </main>

        <Footer />
      </div>
    </>
  );
}
